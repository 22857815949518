* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  background: #fafafa;
  color: #626262;
  font: 16px/1.3 'Segoe UI', Arial, sans-serif;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

main {
  max-width: 400px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
}

h1, h2 {
  color: #2c2c2c;
  text-align: center;
}

.branding {
  margin-bottom: 20px;
}

h1 {
  font: 400 90px/1 'Montserrat';
  margin: 0 0 20px;
}

h2 {
  margin: 0 0 20px;
  font-weight: 400;
  line-height: 32px;
  font-size: 28px;
}

ul {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
}

ul li {
  display: inline-block;
}

ul a {
  display: inline-block;
  padding: 2px 5px;
  background: #f0f0f0;
  border-radius: 3px;
  margin: 5px;
}

p {
  margin: 0 0 10px;
}

small {
  font-size: 0.75rem;
}

a {
  color: #3a8fc8;
  transition: color .3s ease;
  text-decoration: none;
}

a:hover, a:focus, a:active {
  color: #48b0f7;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 13px;
  }
}
